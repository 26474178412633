import React from "react";
import PropTypes from "prop-types";
import { FaArrowDown } from "react-icons/fa/";

const Hero = props => {
  const { scrollToContent, theme } = props;

  return (
    <React.Fragment>
      <section className="hero">
        <h2 id="hidden"> EconTechie - A blog about tech, economics & society. </h2>
        <img src="EconTechieLogo.svg" alt="EconTechie Logo" />
        <br />
        <br />
        <h3> Articles Below </h3>
        <button onClick={scrollToContent} aria-label="scroll">
          <FaArrowDown />
        </button>
      </section>

      {/* --- STYLES --- */}
      <style jsx>{`
        #hidden {
          color: transparent;
          text-indent: -99em;
          font-size: 0.1px;
          margin: 0;
          padding: 0;
        }
        .hero {
          align-items: center;
          color: ${theme.text.color.primary.inverse};
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          height: 91vh;
          padding: ${theme.space.inset.l};
        }

        img {
          align-items: center;
          width: 90%;
        }

        h2 {
          text-align: center;
          font-size: ${theme.hero.h1.size};
          margin: ${theme.space.stack.l};
          color: ${theme.hero.h1.color};
          line-height: ${theme.hero.h1.lineHeight};
          text-remove-gap: both 0 "Open Sans";

          :global(strong) {
            position: relative;

            &::after,
            &::before {
              content: "›";
              color: ${theme.text.color.attention};
              margin: 0 ${theme.space.xs} 0 0;
              text-shadow: 0 0 ${theme.space.s} ${theme.color.neutral.gray.k};
            }
            &::after {
              content: "‹";
              margin: 0 0 0 ${theme.space.xs};
            }
          }
        }

        h3 {
          text-align: center;
          font-size: ${theme.hero.h1.size};
          margin: ${theme.space.stack.xs};
          color: ${theme.hero.h1.color};
          line-height: ${theme.hero.h1.lineHeight};
          text-remove-gap: both 0 "Open Sans";
          animation-duration: ${theme.time.duration.long};
          animation-name: buttonIconMove;
          animation-iteration-count: infinite;

          :global(strong) {
            position: relative;

            &::after,
            &::before {
              content: "›";
              color: ${theme.text.color.attention};
              margin: 0 ${theme.space.xs} 0 0;
              text-shadow: 0 0 ${theme.space.s} ${theme.color.neutral.gray.k};
            }
            &::after {
              content: "‹";
              margin: 0 0 0 ${theme.space.xs};
            }
          }
        }
        button {
          background: transparent;
          border: 0;
          border-radius: 50%;
          font-size: ${theme.font.size.m};
          cursor: pointer;
          width: ${theme.space.xl};
          height: ${theme.space.xl};

          :global(svg) {
            position: relative;
            fill: #99d6f8;
            stroke-width: 40;
            stroke: #99d6f8;
            animation-duration: ${theme.time.duration.long};
            animation-name: buttonIconMove;
            animation-iteration-count: infinite;
          }
        }

        @keyframes buttonIconMove {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
          100% {
            transform: translateY(0);
          }
        }

        @from-width tablet {
          .hero {
            background-color: #1f262e;
          }

          h1 {
            max-width: 90%;
            font-size: ${`calc(${theme.hero.h1.size} * 1.3)`};
          }

          button {
            font-size: ${theme.font.size.l};
          }
        }

        @from-width desktop {
          h1 {
            max-width: 80%;
            font-size: ${`calc(${theme.hero.h1.size} * 1.5)`};
          }

          button {
            font-size: ${theme.font.size.xl};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Hero.propTypes = {
  scrollToContent: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired
};

export default Hero;
